import HabaneroClient, {
  Projects,
  EventNames as _EventNames
} from '@parsleyhealth/habanero-client';

import { HABANERO_BEARER_TOKEN, HABANERO_URL } from '../constants/env';

declare global {
  interface Window {
    habanero: HabaneroClient<Projects.INSIGHTS>;
    heap: any;
  }
}

export const createClient = (): void => {
  if (!HABANERO_URL || !HABANERO_BEARER_TOKEN) {
    return;
  }

  const client = new HabaneroClient(
    HABANERO_URL,
    HABANERO_BEARER_TOKEN,
    Projects.INSIGHTS,
    window.localStorage,
    process.env.REACT_APP_SITE_URL
  );

  window.habanero = client;
};

export const EventNames = _EventNames;
